import { useEffect, useState } from "react";
import {links} from "./LandingNavScroll.data";

export const useLandingNavScroll = () => {
    const [isLandingNavScrollVisible, setIsLandingNavScrollVisible] = useState(false);
    const [activeLink, setActiveLink] = useState(links[0].elementId);
    const [scrollTop, setScrollTop] = useState(0);
    const [isMobileLinksOpen, setIsMobileLinksOpen] = useState(false);

    const listenToScroll = () => {
        const currentScrollPos = window.pageYOffset;
        setScrollTop(currentScrollPos);
    };

    const removeActiveClasses = () => {
        links.map((link) => {
            const element = document.getElementById(link.elementId);
            element && element.classList.remove("active");
        })
    };

    useEffect(() => {
        if(typeof document !== "undefined"){
            links.map((link) => {
                const element = document.getElementById(link.elementId);
                if(element && element.offsetTop - scrollTop < 100){
                    removeActiveClasses();
                    setActiveLink(link.elementId);
                }
            })
        }

        typeof window !== "undefined" &&
        window.addEventListener("scroll", listenToScroll);
        return () => {
            window.removeEventListener("scroll", listenToScroll);
        };
    }, [scrollTop]);

    useEffect(() => {
        setIsMobileLinksOpen(false);
        if(!isLandingNavScrollVisible && typeof document !== "undefined" && scrollTop > 600) {
            setIsLandingNavScrollVisible(true);
            document.getElementById("landing-scroll-nav").classList.add("show")
        }

        if(isLandingNavScrollVisible && typeof document !== "undefined" && scrollTop < 600) {
            setIsLandingNavScrollVisible(false);
            document.getElementById("landing-scroll-nav").classList.remove("show")
        }
    }, [scrollTop]);

    return {
        activeLink, isMobileLinksOpen, setIsMobileLinksOpen
    };
};
