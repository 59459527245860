interface Link {
    value: string,
    elementId: string
}

export const links: Link[] = [
    {value: "Who we are", elementId: "who-we-are"},
    {value: "What we do", elementId: "what-do-we-do"},
    {value: "Our case studies", elementId: "our-case-studies"},
    {value: "Why AllBright", elementId: "why-allbright"},
]