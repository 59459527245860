import React from "react";
import "./SectionSubtitle.styled.scss";

interface ISectionSubtitleProps {
    content: string;
}

export const SectionSubtitle = ({content} : ISectionSubtitleProps) => (
    <div className={"sections"} style={{paddingBottom: "60px"}}>
        <div className={"section-subtitle"}>
            <span className={"content"}>{content}</span>
        </div>
    </div>
);