import React, {useState} from "react";

import "./LandingNavScroll.scss";
import * as Scroll from "react-scroll";
import {links} from "./LandingNavScroll.data";
import {useLandingNavScroll} from "./LandingNavScroll.hook";
import ArrowIconDown from "./../../assets/icons/ArrowIconDown.svg";
import ArrowIconUp from "./../../assets/icons/ArrowIconUp.svg";

export const LandingNavScroll = () => {
    const handleGetInTouch = () => {
        const formButton = document.getElementById(
            "entre-coupon-form"
        );
        if (formButton) {
            formButton.click();
        }
    }

    const { activeLink, isMobileLinksOpen, setIsMobileLinksOpen } = useLandingNavScroll();
    const activeLinkIndex = links.indexOf(links.find(x => x.elementId === activeLink)) + 1;
    return (
        <>
            <div className={`links-mobile-drawer ${isMobileLinksOpen ? 'active' : ''}`}>
                <div className={'content'}>
                    {links.map((link, index) => <Scroll.Link
                        className={`read-more ${activeLink == link.elementId ? 'active' : ''}`}
                        to={link.elementId}
                        spy={true}
                        smooth={true}
                        duration={300}
                        offset={-50}
                    >
                        <div key={`${link.elementId}${link.value}`} className={'link'}>
                            <span>{index + 1}</span>
                            {link.value}
                        </div>
                    </Scroll.Link>)}
                </div>
            </div>
            <div className="landing-nav-scroll" id={"landing-scroll-nav"}>
                <div className={"sections"}>
                    <div className={"links-mobile"} onClick={() => {setIsMobileLinksOpen(!isMobileLinksOpen)}}>
                        <span>{`${activeLinkIndex < 10 ? "0" + activeLinkIndex : activeLinkIndex}`}</span>
                        {links.find(x => x.elementId === activeLink).value}
                        {isMobileLinksOpen ? <ArrowIconUp/>  : <ArrowIconDown/>}
                    </div>
                    <div className={"links"}>
                        {links.map((x) => (
                            <Scroll.Link
                                className={`read-more ${activeLink == x.elementId ? 'active' : ''}`}
                                to={x.elementId}
                                spy={true}
                                smooth={true}
                                duration={300}
                                offset={-50}
                            >
                                <span>{x.value}</span>
                            </Scroll.Link>
                        ))}
                    </div>

                    <div className={"call-to-action"}>
                        <button className="button-red" onClick={handleGetInTouch}>CONTACT</button>
                    </div>
                </div>
            </div>
        </>
    )};