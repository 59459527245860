import React from "react";

import "../WhyFlutter/WhyFlutter.scss";
import "./HealthLanding.scss";

import * as Icons from "../icons";

import heroImage from "../../../assets/imgs/services/health-care-bg.jpeg";
import ProfKosiuk from "../../../assets/imgs/services/profKosiuk.png";
import Captio from "../../../assets/preview-captio.png";
import Kardiolog from "../../../assets/preview-kardiolog.png";
import Telemedicine from "../../../assets/telemedicine.png";
import AppointmentSystem from "../../../assets/appointment-system.png";
import WomenHealthApp from "../../../assets/women-health-app.png";
import WeightBodyApp from "../../../assets/weight-body-app.png";

import { SectionTitle } from "../shared/SectionTitle/SectionTitle";
import { Intro } from "../shared/Intro/Intro";
import { Hero } from "../shared/Hero/Hero";
import { SectionSubtitle } from "../shared/SectionSubtitle";
import { IconVariants, ItemsGrid } from "../shared/ItemsGrid/ItemsGrid";
import {
  TwoColumnsContent,
  TwoColumnsContentVariants,
} from "../shared/TwoColumnsContent/TwoColumnsContent";
import { Process } from "../shared/Process/Process";
import { TestimonialSlider } from "../../TestimonialSlider/TestimonialSlider";
import { ContactUs } from "../shared/ContactUs/ContactUs";
import { ServicesBlogPosts } from "../shared/ServicesBlogPosts/ServicesBlogPosts";
import { ContinueReading } from "../../ContinueReading/ContinueReading";
import { OurApplications } from "../shared/OurApplications/OurApplications";

export const HealthLanding = ({ posts }) => (
  <div className={"why-flutter"}>
    <Hero
      title={
        <h1>
          Dedicated to reliable and secure digital <span>healthcare</span>
        </h1>
      }
      subtitle={
        <>
          Create healthtech apps to improve patient monitoring and care.
          <br /> Want a healthtech app that doctors and patients will both
          appreciate? Let’s talk.
        </>
      }
      heroImage={heroImage}
    />
    <div id={"who-we-are"}>
      <Intro
        sectionTitle={
          <SectionTitle
            number={1}
            title={"Build healtcare apps with us!"}
            isBorderBottom={true}
          />
        }
        leftColumn={[
          <div className={"content"}>
            At AllBright, we produce exceptional <b>healthtech applications</b>{" "}
            for clients in the health and medical industries.
          </div>,
          <div className={"content"}>
            We work both with{" "}
            <b>startups and established healthcare organizations</b>, such as
            hospitals.
          </div>,
          <div className={"icon"}>
            <Icons.GreyOctagon />
            <Icons.Code />
          </div>,
        ]}
        rightColumn={[
          <div className={"content"}>
            Our digital health products facilitate patient self-care and
            monitoring. They improve preventive metrics and reaction times in
            risky situations. The products also support doctors and
            organizations in their daily routines and with cost management. The
            products streamline information processing, so forming a picture of
            patient conditions, appointments, and available health resources
            becomes straightforward.
          </div>,
          <div className={"content"}>
            We understand the nature and complexity of the problems that
            healthcare organizations are facing nowadays. We know it's essential
            to provide tools that simplify patient care, support organizational
            processes, and help physicians focus on their patient's health.
          </div>,
          <div className={"content"}>
            We are aware of the various regulatory requirements in different
            markets. We always make sure that our products and processes adhere
            strictly to these regulations.
          </div>,
        ]}
        isTopBorderRed={true}
        customStyle={{ marginTop: "60px" }}
      />
    </div>
    <SectionSubtitle content={"who work with us"} />
    <div className={"sections"}>
      <div className={"businesses"}>
        {[
          "Health startups",
          "Wellbeing startups",
          "Medical startups",
          "Hospitals and clinics",
          "diagnostic labs",
          "Medical labs",
        ].map((value) => {
          return (
            <div className={"item"} key={value}>
              {value}
            </div>
          );
        })}
      </div>
    </div>
    <SectionSubtitle content={"services"} />
    <Intro
      sectionTitle={null}
      leftColumn={[
        <div className={"content"}>
          We bring our knowledge and expertise in <b>health technology</b> to
          your product!
        </div>,
        <div className={"icon"}>
          <Icons.HealthCareGraphic />
        </div>,
      ]}
      rightColumn={[
        <div className={"content"}>
          <b>Health monitoring and companion applications</b>
        </div>,
        <div className={"content"} style={{ marginBottom: "60px" }}>
          Apps designed to raise patient awareness, assist them in disease
          treatment activities, and aid in a faster and more controlled
          recovery. We develop solutions for health self-monitoring, medical
          treatment and prescription reminders, physical activity tracking, and
          convalescence progress tracking. Our products integrate with smart
          sensors and wearable devices.
        </div>,
        <div className={"content"}>
          <b>Wellness Applications</b>
        </div>,
        <div className={"content"} style={{ marginBottom: "60px" }}>
          These apps help to improve a patient's overall health. Mental health
          apps, fitness apps, and diet and nutrition apps are a few examples.
        </div>,
        <div className={"content"}>
          <b>Medical and m-health applications</b>
        </div>,
        <div className={"content"}>
          Combining existing standards with AI, these apps give doctors and
          hospitals cutting-edge technologies to help them make well-informed
          and up-to-date decisions. They help clinics and hospitals stay
          organized by streamlining medical and administrative personnel
          workflows, cutting costs, and reducing unnecessary stress on the
          facility.
        </div>,
      ]}
    />
    <div id={"what-do-we-do"}>
      <ItemsGrid
        items={[
          {
            title: "Research and product prototyping",
            desc:
              "Get a complete understanding of product objectives, functions, and target user groups. Determine which design strategies are most likely to meet health, educational, and business objectives. Test the assumptions by quick prototyping.",
            icon: <Icons.MobilePhone />,
          },
          {
            title: "Business model and healthcare advisory",
            desc:
              "Having participated in many mobile and health products, we can add value to your advisory team. We help you validate and expand the product’s value proposition and business model.",
            icon: <Icons.SmallBriefCaseRed />,
          },
          {
            title: "Prepare the right development and publication strategy",
            desc:
              "Development and publication strategy is a crucial factor for success. Publish early and iterate quickly. The priorities and tactics depend on business needs, market maturity, the complexity of implementation, the budget and time constraints.",
            icon: <Icons.CodeRed />,
          },
          {
            title: "Build complex medical platforms and ecosystems",
            desc:
              "Comprehensive, advanced technological solutions that form complete platforms. We bring together all the pieces needed to develop an ecosystem: backend, back-office, web and mobile. Our applications act as stand-alone products and integrate into more extensive systems, such as a hospital’s infrastructure.",
            icon: <Icons.FourWindowsRed />,
          },
          {
            title: "Assure security standards for your product",
            desc:
              "We design and build systems to conform to GDPR and HIPAA standards. Medical applications adhere to ISO 13845 and Digital Health Applications (DiGA) certificates. Integration of direct communication with hospitals is via the well-defined healthcare data exchange standards (FHIR), HL7 and German Krankenhauszukunftsgesetz (KHZG).",
            icon: <Icons.LockRed />,
          },
          {
            title: "Great products designed for doctors and patients",
            desc:
              "Designing digital healthcare and medical products means providing solutions that match your patients or the organization's expectations. Also, it means ensuring the product is engaging, that users will enjoy and benefit from it. We make sure the UX is well-designed, and the UI is clean and aligns closely with the app's purpose. All our products meet the highest accessibility standards, so people with limited abilities can use them.",
            icon: <Icons.DoctorRed />,
          },
          {
            title:
              "Get your app developed and meeting the highest quality standards",
            desc:
              "Our team of developers and testers is available to build and deliver your product in a very organized and efficient way! Throughout the whole app development cycle, our QA experts are verifying and assuring the highest quality of the app!",
            icon: <Icons.FlagTypeThingRed />,
          },
          {
            title: "Prepare your app for publication",
            desc:
              "App Store Optimization (ASO) and Search Engine Optimization (SEO) can get left out during the app publication stage. We help you to leverage the recognition and popularity of your products.",
            icon: <Icons.WindowUpRed />,
          },
          {
            title: "Get your product monitored and supported",
            desc:
              "After publication, your application needs a team of professionals to react when customers request changes. Your app needs to reconcile with the newest OS versions and devices released on the market. We make sure your app always works perfectly!",
            icon: <Icons.WindowSearchRed />,
          },
        ]}
        sectionTitle={<SectionTitle number={2} title={"How we can help you"} />}
        iconVariant={IconVariants.CIRCLE}
      />
    </div>
    <Intro
      sectionTitle={
        <SectionTitle
          number={3}
          title={"Security compliance"}
          isBorderBottom={true}
        />
      }
      leftColumn={[
        <div className={"icon"}>
          <Icons.TabletAndChart />
        </div>,
      ]}
      rightColumn={[
        <div className={"content"}>
          Protections for <b>medical records</b> and other sensitive data must
          meet the most rigorous <b>security standards.</b>
        </div>,
        <div className={"content"}>
          We ensure that all systems and applications are highly secure and can
          defend against fraudulent attacks.
        </div>,
        <div className={"content"}>
          We build our products and services according to our security
          standards.
        </div>,
      ]}
      customStyle={{ marginTop: "60px" }}
    />
    <SectionSubtitle content={"security standards"} />
    <div className={"sections"}>
      <div className={"security-standards"}>
        {[
          "GDPR",
          "HIPAA",
          "ISO 13485",
          "ISO 14971",
          "DiGA",
          "FHIR",
          "HL7",
          "KHZG",
        ].map((value) => {
          return (
            <div className={"item"} key={value}>
              {value}
            </div>
          );
        })}
      </div>
    </div>
    <TwoColumnsContent
      sectionTitle={
        <SectionTitle
          title={"What can we do!"}
          number={4}
          isBorderBottom={true}
        />
      }
      variant={TwoColumnsContentVariants.WHITE}
      items={[
        {
          title: "Feasibility study and conceptualization",
          content:
            "The business and tech viability of the product is evaluated, which is then visualised in a design concept.",
          image: <Icons.PhoneAndChart3 />,
        },
        {
          title: "Prototypes",
          content:
            "A clickable version of the app to help validate the UX and UI assumptions of the product (user interactions, user-friendliness and intuitive interface).",
          image: <Icons.Browser />,
        },
        {
          title: "Proof Of Concept",
          content:
            "Validate assumptions with your target users and check if the idea is technically feasible.",
          image: <Icons.TwoPeople />,
        },
        {
          title: "MVP",
          content:
            "A fully working version of your product containing the core features; lets you collect initial user feedback.",
          image: <Icons.TwoPhones />,
        },
        {
          title: "Long-term projects",
          content:
            "We build, release and then monitor - handling long-term maintenance and enhancements as needed. We can adjust the product's behavior to work with new firmware and recent OS versions. We can also implement new features to meet customer demands.",
          image: <Icons.TwoPeopleAndChart />,
        },
      ]}
    />
    <div className={"white-bg"} style={{ marginTop: "-35px" }}>
      <div className={"sections"}>
        <SectionTitle
          number={5}
          title={"Range of offer"}
          isBorderBottom={true}
        />
        <div className={"offer-range"}>
          {[
            "Telemedicine",
            "Patient engagement",
            "Smart sensors monitoring apps (Internet of Healthcare Things)",
            "Virtual doctors and clinics",
            "Appointment booking systems",
            "Biometric data collection and analysis",
            "Secure applications for patients",
            "Utility applications for medics",
            "Accessibility products",
            "Health monitoring",
            "Mental health apps",
            "Dieting and Nutrition",
            "Health reminders",
            "Remote Patient Monitoring",
            "Health monitoring apps",
            "Fitness Apps",
            "Wearables",
            "AI-based diagnostic systems",
            "Automatic analysis of wearables data points",
            "Electronic Health Records",
          ].map((value) => {
            return (
              <div className={"item"} key={value}>
                <span>{value}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    <div className={"white-bg"} style={{ marginTop: "-35px" }}>
      <div className={"sections"} id={"our-case-studies"}>
        <SectionTitle number={6} title={"Medical Partners"} />
        <div className={"partners"}>
          {[
            <div style={{ filter: "grayscale(100%)" }}>
              <Icons.MedreferLogoBlack />
            </div>,
            <Icons.KardiologLogoBlack />,
            <Icons.CaptioLogoBlack />,
          ].map((value, i) => {
            return (
              <div className={"item"} key={`partners_${i}`}>
                {value}
              </div>
            );
          })}
        </div>
      </div>
    </div>
    <Intro
      sectionTitle={
        <SectionTitle
          number={7}
          title={"Medical Advisor"}
          isBorderBottom={true}
        />
      }
      leftColumn={[
        <div className={"icon"} style={{ marginTop: "80px" }}>
          <img src={ProfKosiuk} />
        </div>,
      ]}
      rightColumn={[
        <div className={"content title"} style={{ marginTop: "80px" }}>
          Prof. Jedrzej Kosiuk
        </div>,
        <div className={"content subtitle"}>MD, PhD</div>,
        <div className={"content list"}>
          <ul>
            <li>Assoc. Prof. Dr. Dr. Jedrzej Kosiuk, FESC</li>
            <li>University of Leipzig Faculty of Medicine</li>
            <li>Head of Rhythmology Department Helios Klinikum Köthen</li>
            <li>Visiting professor Medical University of Warsaw</li>
            <li>Associate Editor EP Europace</li>
            <li>SIC member European Heart Rhythm Association</li>
            <li>
              {
                "Prof. wrote more than 70 publications in top journals with >500 IF over the past 7 years."
              }
            </li>
          </ul>
        </div>,
      ]}
      customStyle={{ marginTop: "60px" }}
    />
    <OurApplications
      sectionTitle={
        <SectionTitle
          number={8}
          title={"Health products that we have built"}
          isBorderBottom={true}
        />
      }
      customProducts={[
        {
          name: "KardioLog",
          description:
            "Health applications for patients with cardiac diseases. Designed according to The European Society of Cardiology (ESC) guidelines. It makes it easier for heart patients to track blood pressure, heart rate, and weight. It also encourages patients to maintain a suitable level of physical activity and to remember to take medications.",
          images: [Kardiolog],
          link: null,
          customImgClass: "service",
        },
        {
          name: "captio",
          description:
            "An audio-video application developed to help people with hearing loss. Thanks to using a real-time transcription speech to text model, the app allows people with hearing loss to communicate with ease using a mobile application or web browser video connection.",
          images: [Captio],
          link: null,
          customImgClass: "service",
        },
        {
          name: "Audio/video solution for telemedicine",
          description:
            "A highly secure telemedicine solution that enables patients and physicians to conduct remote audio-video consultations.",
          images: [Telemedicine],
          link: null,
          customImgClass: "service",
        },
        {
          name: "Medical appointment management system (under NDA)",
          description:
            "A messaging service for business communities. Users can search for and browse members of their group based on their traits, and interact with them via a custom-built chat feature.",
          images: [AppointmentSystem],
          link: null,
          customImgClass: "service",
          hidePolygon: true,
        },
        {
          name: "Women Health app (under NDA)",
          description:
            "A mobile application designed by physicians to assist women during breast cancer therapy.",
          images: [WomenHealthApp],
          link: null,
          customImgClass: "service",
          hidePolygon: true,
        },
        {
          name: "weight body app (under NDA)",
          description:
            "This weight and body composition monitoring application includes IoT integration. People can use it with popular smart scales, such as those made by Garmin, Withings, Xiaomi, Anker or Eufy.",
          images: [WeightBodyApp],
          link: null,
          customImgClass: "service",
          hidePolygon: true,
        },
      ]}
    />
    <section className="testimonial-slider white-bg color-black border-grey">
      <div className={"sections"}>
        <TestimonialSlider page="index" title={"Clients about us"} />
      </div>
    </section>
    <ContactUs
      title={"Do you want to discuss your mobile app project with an expert?"}
      buttonText={"contact us"}
    />
    <div className={"white-bg why-allbright"}>
      <div
        className={"sections"}
        style={{ paddingTop: "50px" }}
        id="why-allbright"
      >
        <SectionTitle number={9} title={"Why Allbright"} />
        <div className={"row"}>
          <div className={"column left"}>
            <div className={"content"}>
              At AllBright, we help our clients to build{" "}
              <b>great digital health products.</b> We've participated in many
              projects dedicated to improving healthcare today.
            </div>
            <div className={"content"}>
              Each of our products has a different objective, and each serves a
              different set of users. Our <b>understanding and experience</b>{" "}
              are available to use in your project.
            </div>
            <div className={"icon"}>
              <Icons.PinkOctagon />
              <Icons.NeuronsRed />
            </div>
          </div>
          <div className={"column right"}>
            <div className={"content"}>
              We assist you at every step in the application development
              process, from medical expert consultation, product research and
              definition, and production and deployment.
            </div>
            <div className={"content"}>
              We advise you on how to turn your idea into a product. We assist
              you if you need to build a prototype quickly, present it to your
              board or investors or test it with your first clients. We take
              care of the technological challenges and prepare a development and
              publication strategy to be efficient. Our UX/UI team analyzes your
              customer’s behaviour and business environment, then creates
              beautiful designs focused on your customer’s interaction with the
              app.
            </div>
            <div className={"content"}>
              With us, you can feel safe about quality and budget. We always
              make sure that our solutions are a good fit for your business and
              budget. We understand the importance of prioritization and
              achieving objectives and competitive advantage through the
              strategic organization of the product plan and publication dates.
            </div>
            <div className={"content"}>
              Each product evolves with time; therefore, it needs designing with
              evolution and growth in mind. At AllBright, we assure that
              scalability and app maintenance is efficient, avoiding foreseeable
              problems.
            </div>
            <div className={"content"}>
              We also make sure that your product is app store optimized and
              ready for a successful market entry.
            </div>
          </div>
        </div>
      </div>
    </div>
    <ItemsGrid
      items={[
        {
          title: "15 years",
          desc: "of commercial experience",
          icon: <Icons.BriefcaseRed />,
        },
        {
          title: "12 years",
          desc: "building scalable applications with millions of users",
          icon: <Icons.PeopleNetworkRed />,
        },
        {
          title: "6 years",
          desc: "experience in secure fintech projects",
          icon: <Icons.BooksRed />,
        },
        {
          title: "3 years",
          desc: "of experience with medical and health applications",
          icon: <Icons.HeartRed />,
        },
        {
          title: "PCI DSS / HIPAA",
          desc: "Experience with GDPR, PCI DSS and HIPAA security compliance",
          icon: <Icons.GlobusRed />,
        },
        {
          title: "mentoring",
          desc:
            "Built mobile applications for big corporations and brand-new startups",
          icon: <Icons.HandsRed />,
        },
        {
          title: "Details",
          desc: "Great attention to details",
          icon: (
            <div style={{ paddingTop: "6px" }}>
              <Icons.EyeRed />
            </div>
          ),
        },
        {
          title: "Product team",
          desc: "UX and UI matters to us",
          icon: <Icons.PencilRed />,
        },
        {
          title: "10 days",
          desc: "Have a rapid prototype built in as little as 10 days!",
          icon: <Icons.WindowsRed />,
        },
        {
          title: "transparent",
          desc:
            "Transparent development process - get new features every 2 weeks!",
          icon: <Icons.WindowHandRed />,
        },
        {
          title: "ip",
          desc: "Transfer of IP",
          icon: <Icons.WindowRefreshRed />,
        },
        {
          title: "free trial",
          desc: "2 weeks risk-free trial",
          icon: <Icons.WindowClockRed />,
        },
      ]}
      sectionTitle={null}
      iconVariant={IconVariants.OCTAGON_TRANSPARENT}
    />
    <Process
      sectionTitle={<SectionTitle number={10} title={"Development process"} />}
    />
    <section className="testimonial-slider white-bg color-black border-grey">
      <div className={"sections"}>
        <TestimonialSlider page="index" title={"Clients about us"} />
      </div>
    </section>
    <ContactUs
      title={"Want to talk with a mobile app expert? Contact us!"}
      buttonText={"Get in touch"}
    />
    <ServicesBlogPosts
      posts={posts}
      sectionTitle={
        <SectionTitle number={11} title={"Mobile applications blog articles"} />
      }
    />
    <div className={"sections"} style={{ paddingBottom: "0" }}>
      <ContinueReading nextSection="React Native" link="React Native" />
    </div>
  </div>
);
